/* components/Editor/styles.modules.css */

.wrapper {
  padding: 2rem 3rem;
}

.label {
  font-size: 1rem;
  font-weight: 500;
}

.editor {
  margin-top: 1rem;
  height: 500px;
}